// export type  PublicationType = "news" | "blog" | "questionnaire";

import { EventAttachmentType } from "src/dto/dto";

// export const PublicationTypeValues: PublicationType[] = [ "news", "blog", "questionnaire" ];
export type  PublicationType = number;
export const PublicationType = {
    NEWS: EventAttachmentType.NEWS, // 9
    POST: EventAttachmentType.POST, // 13
    QUESTIONNAIRE: EventAttachmentType.QUESTIONNAIRE, // 10

    9: "NEWS",
    13: "BLOG",
    10: "QUESTIONNAIRE",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}
export const publicationTypes = [
    { id: EventAttachmentType.NEWS, label: 'News headline', icon: 'content' },
    { id: EventAttachmentType.POST, label: 'News article', icon: 'content' },
    { id: EventAttachmentType.DOCUMENTATION, label: 'Documentation', icon: 'content' } /*,
    { id: EventAttachmentType.QUESTIONNAIRE, label: 'Questionnaire', icon: 'content' } */
];
